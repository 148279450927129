
import { mapWritableState } from 'pinia'
import { useMarketStore } from '~/stores/market'

export default {
  name: 'MPPreOrderConfirmPopup',
  setup() {
    const marketStore = useMarketStore()

    return { marketStore }
  },

  methods: {
    closeModal() {
      this.marketStore.showPreOrderConfirmPopup = false
      this.marketStore.setPreOrderProduct('')
    },
  },
}
